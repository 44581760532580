import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import LayoutUnauthenticated from '@/layouts/LayoutUnauthenticated.vue'
import Campaigns from '@/views/Campaigns.vue'
import Dashboard from '@/views/Dashboard.vue'
import CampaignDetailsPage from '@/views/CampaignDetailsPage.vue'
import ActiveCampaignDetails from '@/views/ActiveCampaignDetails.vue'
import Support from '@/views/Support.vue'
import Settings from '@/views/Settings.vue'
import CampaignPolicies from '@/views/CampaignPolicies.vue'
import ProfilePreferences from '@/components/app-settings/ProfilePreferences.vue'
import ProfileSettings from '@/components/app-settings/ProfileSettings.vue'
import Faq from '@/views/Faq.vue'
import FaqDetails from '@/components/faq/FaqDetails.vue'
import AgentFeedback from '@/views/AgentFeedback.vue'
import AgentFeedbackDetails from '@/views/AgentFeedbackDetails.vue'
import Payroll from '@/views/Payroll.vue'
import EmptyCampaignsPage from '@/views/EmptyCampaignsPage.vue'
import { useLayoutStore } from '@/stores/layout'
import LayoutBlank from '@/layouts/LayoutBlank.vue'
import { storeToRefs } from 'pinia'
import { useOnboardingStore } from '@/stores/onboarding'
import { objectHasKeys } from '@/utils/utils'
import { useProfileStore } from '@/stores/profile'
import ReviewInteraction from '@/views/ReviewInteraction.vue'

const SSO_PARAMS = ['sso', 'brand_id', 'locale_id', 'return_to', 'timestamp']

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: LayoutUnauthenticated,
        needAuthentication: false
      }
    },
    // TODO: implement signup
    {
      path: '/password-reset',
      name: 'PasswordReset',
      component: () => import('@/views/PasswordReset.vue'),
      meta: {
        layout: LayoutUnauthenticated,
        needAuthentication: false
      }
    },
    {
      path: '/confirm-password',
      name: 'PasswordConfirmation',
      component: () => import('@/views/PasswordConfirmation.vue'),
      meta: {
        layout: LayoutUnauthenticated,
        needAuthentication: false
      }
    },
    {
      path: '/onboarding',
      name: 'Onboarding',
      component: () => import('@/views/Onboarding.vue'),
      meta: {
        layout: LayoutBlank,
        needAuthentication: true
      }
    },
    {
      path: '/',
      redirect: '/dashboard',
      meta: {
        layout: LayoutDefault
      },
      children: [
        {
          path: '/agents',
          name: 'Agents',
          component: () => import('@/views/Agents.vue'),
          meta: {
            needAuthentication: true,
            permittedRoles: ['Admins']
          }
        },
        {
          path: '/opt-ins',
          name: 'OptIns',
          component: () => import('@/views/OptIns.vue'),
          meta: {
            needAuthentication: true,
            permittedRoles: ['Admins']
          }
        },
        {
          path: '/observations',
          name: 'Observations',
          component: () => import('@/views/Observations.vue'),
          meta: {
            needAuthentication: true,
            permittedRoles: ['Admins']
          }
        },
        {
          path: '/campaigns',
          name: 'Campaigns',
          component: Campaigns,
          meta: {
            needAuthentication: true,
            permittedRoles: []
          }
        },
        {
          path: '/campaign-policies',
          name: 'Campaign Policies',
          component: CampaignPolicies,
          meta: {
            needAuthentication: true,
            permittedRoles: []
          }
        },
        {
          path: '/campaigns/:id',
          name: 'CampaignDetailsPage',
          component: CampaignDetailsPage,
          meta: {
            needAuthentication: true,
            permittedRoles: []
          }
        },
        {
          path: '/active-campaigns/:id',
          name: 'ActiveCampaignDetails',
          component: ActiveCampaignDetails,
          meta: {
            needAuthentication: true,
            permittedRoles: []
          }
        },
        {
          path: '/active-campaigns/no-campaigns',
          name: 'NoActiveCampaigns',
          component: EmptyCampaignsPage,
          meta: {
            needAuthentication: true,
            permittedRoles: []
          }
        },
        {
          path: '/agent-feedback',
          name: 'AgentFeedback',
          component: AgentFeedback,
          meta: {
            needAuthentication: true,
            permittedRoles: []
          },
          children: [
            {
              path: ':id',
              name: 'AgentFeedbackDetails',
              component: AgentFeedbackDetails
            }
          ]
        },
        {
          path: '/support',
          name: 'Support',
          component: Support,
          meta: {
            needAuthentication: true,
            permittedRoles: []
          }
        },
        {
          path: '/my-pay',
          name: 'Payroll',
          component: Payroll,
          meta: {
            needAuthentication: true,
            permittedRoles: []
          }
        },
        {
          path: '/documents',
          name: 'Documents',
          component: () => import('@/views/Documents.vue'),
          meta: {
            needAuthentication: true,
            permittedRoles: []
          }
        },
        {
          path: '/prompts',
          name: 'Prompts',
          component: () => import('@/views/Prompts.vue'),
          meta: {
            needAuthentication: true,
            permittedRoles: []
          }
        },
        // {
        //   path: '/simulated-calls',
        //   name: 'SimulatedCalls',
        //   component: () => import('@/views/SimulatedCalls.vue'),
        //   meta: {
        //     needAuthentication: true,
        //     permittedRoles: []
        //   }
        // },
        // {
        //   path: '/scheduled-calls',
        //   name: 'ScheduledCalls',
        //   component: () => import('@/views/ScheduledCalls.vue'),
        //   meta: {
        //     needAuthentication: true,
        //     permittedRoles: []
        //   }
        // },
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            needAuthentication: true,
            permittedRoles: ['Agents']
          }
        },
        {
          path: '/settings',
          name: 'Settings',
          component: Settings,
          meta: {
            needAuthentication: true,
            permittedRoles: []
          },
          children: [
            {
              path: '/settings/profile',
              component: ProfileSettings
            },
            {
              path: '/settings/preferences',
              component: ProfilePreferences
            }
          ]
        },
        {
          path: '/faq',
          name: 'Faq',
          component: Faq,
          meta: {
            needAuthentication: true,
            permittedRoles: []
          },
          children: [
            {
              path: '/faq/:chapter',
              component: FaqDetails
            }
          ]
        },
        {
          path: '/campaigns/:pathMatch(.*)*',
          name: 'NotFound',
          component: () => import('@/views/Error.vue')
        },
        {
          path: '/:pathMatch(.*)*',
          name: 'NotFound',
          component: () => import('@/views/Error.vue')
        },
        {
          path: '/review-interaction',
          name: 'ReviewInteraction',
          component: ReviewInteraction,
          meta: {
            needAuthentication: true,
            permittedRoles: ['Agents']
          }
        }
      ]
    }
  ],
  scrollBehavior() {
    return { top: 0 }
  }
})

router.beforeEach((to) => {
  const { setLoadingState } = useLayoutStore()
  const { isCampaingReviewer } = storeToRefs(useProfileStore())
  const { onboardingCompleted } = storeToRefs(useOnboardingStore())
  const authentication: string | null = localStorage.getItem('auth')
  const requiresAuth = to.matched.some((record) => record.meta.needAuthentication)

  setLoadingState(true)

  if (authentication) {
    axios.defaults.headers.common = {
      Authorization: 'Bearer ' + JSON.parse(authentication).token,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': '*'
    }
  }

  if (requiresAuth && !authentication) {
    return '/login'
  }

  if (to.name === 'Login' && authentication) {
    if (objectHasKeys(to.query, SSO_PARAMS)) {
      return undefined
    } else {
      return '/'
    }
  }

  if (to.name === 'Onboarding') {
    if (!onboardingCompleted.value) {
      return undefined
    } else if (onboardingCompleted.value) {
      return '/'
    }
  }

  if (to.name === 'ReviewInteraction') {
    if (!isCampaingReviewer.value) {
      return '/'
    }
  }

  if (!to.matched.length) {
    return '/'
  }
})

export default router
