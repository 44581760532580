<script setup lang="ts">
import { inject, ref, computed } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import { RouterLink, useRouter } from 'vue-router'
import useLocalStorage from '@/composables/useLocalStorage'
import NotificationList from '@/components/notifications/NotificationList.vue'
import ActiveCampaigns from '@/components/campaigns/ActiveCampaigns.vue'
import { useNotificationStore } from '@/stores/notifications'
import { useProfileStore } from '@/stores/profile'
import { useCampaignStore } from '@/stores/campaigns'
import { useLayoutStore } from '@/stores/layout'
import { useFeedbackStore } from '@/stores/feedback'
import SupportForm from '@/components/support/SupportForm.vue'

const router = useRouter()
const notificationStore = useNotificationStore()
const profileStore = useProfileStore()
const campaignStore = useCampaignStore()
const layoutStore = useLayoutStore()
const feedbackStore = useFeedbackStore()


const menuIsOpen = ref(false)
const emitter: any = inject('emitter')
const showNotifications = ref(false)
const showActiveCampaigns = ref(false)
const showSupport = ref(false)

const canReviewInteractions = computed(() => {
  return profileStore.isCampaingReviewer
})



emitter.on('openMenu', () => {
  menuIsOpen.value = true
})

const { hasPermission } = useLocalStorage()

const navigationItems = computed(() => ([
  {
    name: 'Dashboard',
    icon: 'mdi-view-dashboard',
    url: '/dashboard',
    roles: ['all']
  },
  {
    name: 'Agents',
    icon: 'mdi-face-agent',
    url: '/agents',
    roles: ['Admins']
  },
  {
    name: 'Opt-ins',
    icon: 'mdi-list-status',
    url: '/opt-ins',
    roles: ['Admins']
  },
  {
    name: 'Observations',
    icon: 'mdi-camera-control',
    url: '/observations',
    roles: ['Admins']
  },
  {
    name: 'Notifications',
    icon: 'mdi-view-dashboard',
    url: '#',
    roles: ['all']
  },
  {
    name: 'Open Campaigns',
    icon: 'mdi-cards-variant',
    url: '/campaigns',
    roles: ['all']
  },
  {
    name: 'My Campaigns',
    icon: 'mdi-folder-check',
    url: campaignStore.getActiveCampaigns?.length ? `/active-campaigns/${campaignStore.getActiveCampaigns[0].id}` : '/active-campaigns/',
    roles: ['']
  },
  {
    name: 'Agent Feedback',
    icon: 'mdi-star-box',
    url: '/agent-feedback',
    roles: ['']
  },
  {
    name: 'My Pay',
    icon: 'mdi-cash',
    url: '/my-pay',
    roles: ['']
  },
  {
    name: 'FAQ',
    icon: 'mdi-help-circle',
    url: 'https://shyftoffagent.zendesk.com/auth/v2/login/signin?return_to=https://support.shyftoff.com/hc/en-us',
    roles: ['']
  },
  {
    name: 'Peer to Peer QA',
    icon: 'mdi-clipboard-list',
    url: '/review-interaction',
    roles: ['']
  }
  /* {
    name: 'Prompts',
    icon: 'mdi-clipboard-list-outline',
    url: '/prompts',
    roles: ['Admins', 'Agents']
  }, */
  // {
  //   name: 'Simulated Calls',
  //   icon: 'mdi-clipboard-list-outline',
  //   url: '/simulated-calls',
  //   roles: []
  // },
  // {
  //   name: 'Start Call',
  //   icon: 'mdi-phone',
  //   url: '/scheduled-calls',
  //   roles: []
  // }
]))

function closeMenu() {
  menuIsOpen.value = false
  emitter.emit('closeMenu')
}
function logOut() {
  localStorage.clear()
  router.go(0) // refresh the page to clear vuex store (maybe to find better solution)
  router.push('/login')
}
function toggleNotifications() {
  showNotifications.value = !showNotifications.value
}
function toggleActiveCampaigns() {
  showActiveCampaigns.value = !showActiveCampaigns.value
  layoutStore.setDrawerState(showActiveCampaigns.value)
  emitter.emit('toggleActiveCampaigns', showActiveCampaigns.value)
}
function toggleSupportForm() {
  showSupport.value = !showSupport.value
}
const bottomMenuItems = ref([
  {
    title: 'Support',
    link: '/support',
    icon: new URL('@/assets/images/faq-icon.svg', import.meta.url).href
  },
  {
    title: 'Documents',
    link: '/documents',
    icon: new URL('@/assets/images/faq-icon.svg', import.meta.url).href
  },
  {
    title: 'Settings',
    link: '/settings',
    icon: new URL('@/assets/images/settings-icon.svg', import.meta.url).href
  }
])


const profileImage = computed(() => {
  return profileStore?.profile?.agent?.avatar_url ?? ''
})
</script>

<template>
  <aside v-on-click-outside="closeMenu" class="nav-tile tw-relative tw-max-w-[250px] md:tw-max-w-[70px] xl:tw-max-w-[250px] tw-shadow-[6px_0px_14px_0px_rgba(0,0,0,0.07)] md:tw-shadow-none md:!tw-translate-x-0 tw-transition-all tw-scrollbar-thumb-[#FF90D4] tw-scrollbar-track-[#F3EFFA] tw-scrollbar-thin lg:!tw-z-50" :class="{ '-tw-translate-x-full': !menuIsOpen, 'md:!tw-max-w-[320px] xl:!tw-max-w-[500px]': $route.path.includes('active-campaigns') && !showNotifications }">
    <div class="tw-flex flex-column tw-max-w-[250px] md:tw-max-w-[70px] xl:tw-max-w-[250px] tw-bg-shyftoff-purple tw-h-full tw-z-30" :class="{'': $route.path.includes('active-campaigns'), 'tw-min-h-[840px]': !profileStore.isAgentRole}">
      <div class="tw-shrink-0 tw-grow">
        <div class="tw-overflow-hidden md:tw-max-w-[30px] xl:tw-max-w-none md:tw-translate-x-5 xl:-tw-translate-x-[5px] tw-px-3 md:tw-px-0 tw-text-left md:tw-text-center tw-flex tw-justify-between xl:tw-justify-center tw-items-center">
          <img src="@/assets/images/logo-shyftoff--white.svg" alt="ShyftOff" class="logo-img tw-max-w-none md:tw-h-7 xl:tw-h-9 tw-inline-block tw-my-6" />
          <div @click="closeMenu" class="tw-w-9 tw-h-9 tw-bg-shyftoff-grey/20 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-shadow-[0px_2px_12px_rgba(0,0,0,0.06)] tw-cursor-pointer hover:tw-bg-shyftoff-grey/25 md:tw-hidden">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.2806 14.2194C15.3503 14.289 15.4056 14.3718 15.4433 14.4628C15.481 14.5539 15.5004 14.6514 15.5004 14.75C15.5004 14.8485 15.481 14.9461 15.4433 15.0372C15.4056 15.1282 15.3503 15.2109 15.2806 15.2806C15.2109 15.3503 15.1282 15.4056 15.0372 15.4433C14.9461 15.481 14.8485 15.5004 14.75 15.5004C14.6515 15.5004 14.5539 15.481 14.4628 15.4433C14.3718 15.4056 14.2891 15.3503 14.2194 15.2806L8 9.0603L1.78063 15.2806C1.6399 15.4213 1.44903 15.5004 1.25 15.5004C1.05098 15.5004 0.86011 15.4213 0.71938 15.2806C0.57865 15.1399 0.499588 14.949 0.499588 14.75C0.499588 14.551 0.57865 14.3601 0.71938 14.2194L6.93969 7.99999L0.71938 1.78061C0.57865 1.63988 0.499588 1.44901 0.499588 1.24999C0.499588 1.05097 0.57865 0.860095 0.71938 0.719365C0.86011 0.578634 1.05098 0.499573 1.25 0.499573C1.44903 0.499573 1.6399 0.578634 1.78063 0.719365L8 6.93968L14.2194 0.719365C14.3601 0.578634 14.551 0.499573 14.75 0.499573C14.949 0.499573 15.1399 0.578634 15.2806 0.719365C15.4214 0.860095 15.5004 1.05097 15.5004 1.24999C15.5004 1.44901 15.4214 1.63988 15.2806 1.78061L9.06032 7.99999L15.2806 14.2194Z" fill="#F5F5F5"/>
            </svg>
          </div>
        </div>
        <hr class="nav-divider tw-mb-4" />
        <div class="tw-hidden  tw-px-6 tw-py-2">
          <span class="tw-uppercase tw-text-white tw-opacity-50 tw-font-bold tw-text-sm">Menu</span>
        </div>
        <ul class="nav-list tw-space-y-3 ">
          <template v-for="(item, index) in navigationItems" :key="`nav-${index}`">

            <li v-if="hasPermission(item.roles) && !item.roles.includes('Admins') && !['Notifications', 'My Pay', 'FAQ'].includes(item.name) && (item.name !== 'Peer to Peer QA' || (item.name === 'Peer to Peer QA' && canReviewInteractions))" class="nav-item">
              <RouterLink :to="item.url" @click="closeMenu()" class="nav-link tw-text-shyftoff-grey [&.router-link-active>span>i]:tw-text-white tw-leading-none tw-relative" :class="{'tw-bg-[var(--color-pink-main)]': $route.path.includes('active-campaigns') && item.url.includes('active-campaigns')}">
                <span class="tw-inline-block tw-align-middle tw-flex-grow-0 tw-mr-3 tw-leading-[0]">
                  <i :class="`mdi ${item.icon} tw-text-2xl tw-leading-none`"></i>
                </span>
                <span class="md:tw-hidden xl:tw-inline">{{ item.name }}</span>
                <i v-if="item.name === 'Agent Feedback'" class="tw-flex xl:tw-h-[24px] xl:tw-min-w-[24px] tw-h-[20px] tw-min-w-[20px] tw-text-xs tw-rounded-full tw-absolute tw-bg-shyftoff-pink tw-top-1/2 -tw-translate-y-1/2 md:tw-top-auto md:tw-translate-none xl:tw-top-1/2 xl:-tw-translate-y-1/2 tw-right-1 xl:tw-right-2 tw-text-center tw-items-center tw-justify-center tw-font-extrabold tw-px-1 xl:tw-px-2" :class="{'tw-bg-white tw-text-shyftoff-pink': $route.path.includes('agent-feedback')}">
                  {{ feedbackStore.getUnacknowledged }}
                </i>
              </RouterLink>
            </li>

            <li v-if="hasPermission(item.roles) && item.name === 'Notifications'" class="nav-item">
              <a href="#" @click.prevent.stop="toggleNotifications" class="nav-link notifications tw-text-shyftoff-grey [&.router-link-active>span>i]:tw-text-white tw-leading-none tw-flex xl:tw-flex-row tw-relative">
                <span class="tw-inline-block tw-align-middle tw-flex-grow-0 tw-mr-3 tw-leading-[0]">
                  <Teleport to="body">
                    <div v-if="showNotifications" class="tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-[rgba(93,65,140,0.3)] tw-z-0"></div>
                    <Transition name="slide-right">
                      <NotificationList v-if="showNotifications" @closeNotificationEmitted="toggleNotifications" />
                    </Transition>
                  </Teleport>
                  <span class="tw-inline-block tw-align-middle tw-flex-grow-0 tw-leading-[0]">
                    <i :class="`mdi mdi-bell tw-text-2xl tw-leading-none`"></i>
                  </span>
                </span>
                <span class="md:tw-hidden xl:tw-inline notifications">Notifications</span>
                <i v-if="notificationStore.getUnreadNotificationsCount > 0" class="tw-flex xl:tw-h-[24px] xl:tw-min-w-[24px] tw-h-[20px] tw-min-w-[20px] tw-text-xs tw-rounded-full tw-absolute tw-bg-shyftoff-pink tw-top-1/2 -tw-translate-y-1/2 md:tw-top-auto md:tw-translate-none xl:tw-top-1/2 xl:-tw-translate-y-1/2 tw-right-1 xl:tw-right-2 tw-text-center tw-items-center tw-justify-center tw-font-extrabold tw-px-1 xl:tw-px-2">
                  {{ notificationStore.getUnreadNotificationsCount }}
                </i>
              </a>
            </li>
          </template>
        </ul>

        <div v-if="profileStore?.profile?.groups?.includes('Admins')" class="tw-px-[11px] xl:tw-px-6 tw-py-2 tw-mt-6 tw-text-center xl:!tw-text-left">
          <span class="tw-uppercase tw-text-white tw-opacity-50 tw-font-bold tw-text-[11px] xl:tw-text-sm">
            Admins
          </span>
        </div>

        <ul v-if="profileStore?.profile?.groups?.includes('Admins')" class="nav-list tw-space-y-3 ">
          <template v-for="(item, index) in navigationItems" :key="`nav-${index}`">
            <li v-if="hasPermission(item.roles) && item.roles.includes('Admins') && !['My Pay', 'FAQ'].includes(item.name)" class="nav-item">
              <RouterLink :to="item.url" @click="closeMenu()" class="nav-link tw-text-shyftoff-grey [&.router-link-active>span>i]:tw-text-white tw-leading-none" :class="{'tw-bg-[var(--color-pink-main)]': $route.path.includes('active-campaigns') && item.url.includes('active-campaigns')}">
                <span class="tw-inline-block tw-align-middle tw-flex-grow-0 tw-mr-3 tw-leading-[0]">
                  <i :class="`mdi ${item.icon} tw-text-2xl tw-leading-none`"></i>
                </span>
                <span class="md:tw-hidden xl:tw-inline">{{ item.name }}</span>
              </RouterLink>
            </li>
          </template>
        </ul>
      </div>

      <ul class="tw-grow-0 nav-list tw-space-y-3 tw-w-full tw-max-w-[250px]">
        <template v-for="(item, index) in navigationItems" :key="`nav-${index}`">
          <li v-if="hasPermission(item.roles) && ['My Pay', 'FAQ'].includes(item.name)" class="nav-item">
            <RouterLink v-if="item.name !== 'FAQ'" :to="item.url" replace @click="closeMenu()"
              class="nav-link tw-text-shyftoff-grey [&.router-link-active>span>i]:tw-text-white tw-leading-none "
              :class="{ 'tw-bg-[var(--color-pink-main)]': $route.path.includes('active-campaigns') && item.url.includes('active-campaigns') }">
              <span class="tw-inline-block tw-align-middle tw-flex-grow-0 tw-mr-3 tw-leading-[0]">
                <i :class="`mdi ${item.icon} tw-text-2xl tw-leading-none`"></i>
              </span>
              <span class="md:tw-hidden xl:tw-inline">{{ item.name }}</span>
            </RouterLink>

            <a v-else :href="item.url" target="_blank"
              class="nav-link notifications tw-text-shyftoff-grey [&.router-link-active>span>i]:tw-text-white tw-leading-none tw-flex xl:tw-flex-row tw-relative">
              <span class="tw-inline-block tw-align-middle tw-flex-grow-0 tw-mr-3 tw-leading-[0]">
                <i :class="`mdi ${item.icon} tw-text-2xl tw-leading-none`"></i>
              </span>
              <span class="md:tw-hidden xl:tw-inline notifications">{{ item.name }}</span>
            </a>

          </li>
        </template>
      </ul>

      <ul>
        <li>
          <v-btn color="#5723B4" height="54px" rounded="0" class="!tw-w-full tw-border-t tw-border-t-[rgba(255,255,255,0.2)] tw-gap-2 md:tw-gap-1 xl:tw-gap-2 tw-mt-4 aria-[expanded=true]:!tw-bg-red tw-max-w-[250px] md:tw-max-w-[70px] xl:tw-max-w-[250px] tw-pl-2">
            <template #prepend>
              <div v-if="profileImage" class="tw-w-8 tw-h-8">
                <v-img :src="profileImage" aspect-ratio="1/1" :cover="true" class="tw-rounded-full tw-h-full"></v-img>
              </div>
              <div v-else class="!tw-mb-0 tw-border tw-border-solid tw-border-grey-border/50 tw-w-8 tw-h-8 tw-leading-7 tw-text-center tw-rounded-full tw-opacity-80">
                <svg class="tw-inline-block !tw-fill-[#F5F5F5]" width="22" height="21" viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.4527 19.5313C19.906 16.8572 17.5223 14.9397 14.7405 14.0307C16.1165 13.2116 17.1856 11.9634 17.7835 10.4778C18.3815 8.9923 18.4753 7.35155 18.0505 5.80755C17.6257 4.26355 16.7058 2.90168 15.4321 1.93108C14.1584 0.960478 12.6013 0.434814 10.9999 0.434814C9.39857 0.434814 7.84148 0.960478 6.56778 1.93108C5.29408 2.90168 4.3742 4.26355 3.94941 5.80755C3.52462 7.35155 3.6184 8.9923 4.21636 10.4778C4.81432 11.9634 5.88339 13.2116 7.25939 14.0307C4.4776 14.9387 2.09392 16.8562 0.547127 19.5313C0.490403 19.6238 0.452779 19.7267 0.436473 19.834C0.420168 19.9413 0.425512 20.0507 0.452191 20.1559C0.478869 20.2611 0.526341 20.3598 0.591806 20.4463C0.657271 20.5329 0.739403 20.6054 0.833355 20.6597C0.927308 20.7139 1.03118 20.7488 1.13884 20.7623C1.2465 20.7758 1.35577 20.7676 1.4602 20.7381C1.56462 20.7087 1.6621 20.6586 1.74687 20.5909C1.83164 20.5232 1.90199 20.4392 1.95377 20.3438C3.8672 17.037 7.24924 15.0626 10.9999 15.0626C14.7506 15.0626 18.1327 17.037 20.0461 20.3438C20.0979 20.4392 20.1682 20.5232 20.253 20.5909C20.3378 20.6586 20.4353 20.7087 20.5397 20.7381C20.6441 20.7676 20.7534 20.7758 20.861 20.7623C20.9687 20.7488 21.0726 20.7139 21.1665 20.6597C21.2605 20.6054 21.3426 20.5329 21.4081 20.4463C21.4735 20.3598 21.521 20.2611 21.5477 20.1559C21.5744 20.0507 21.5797 19.9413 21.5634 19.834C21.5471 19.7267 21.5095 19.6238 21.4527 19.5313ZM5.31244 7.75008C5.31244 6.6252 5.646 5.52558 6.27096 4.59027C6.89591 3.65497 7.78417 2.92599 8.82343 2.49551C9.86268 2.06504 11.0062 1.95241 12.1095 2.17186C13.2128 2.39131 14.2262 2.933 15.0216 3.72841C15.817 4.52382 16.3587 5.53723 16.5782 6.6405C16.7976 7.74377 16.685 8.88734 16.2545 9.92659C15.824 10.9658 15.095 11.8541 14.1597 12.4791C13.2244 13.104 12.1248 13.4376 10.9999 13.4376C9.49201 13.436 8.04631 12.8362 6.98005 11.77C5.91379 10.7037 5.31405 9.258 5.31244 7.75008Z" />
                </svg>
              </div>
            </template>
            <template #default>
              <span class="tw-text-white tw-normal-case tw-text-base tw-tracking-normal tw-text-left tw-w-full tw-text-ellipsis tw-overflow-hidden">
                {{ profileStore.profile?.agent?.name ?? profileStore.profile?.username }}
              </span>
              <v-menu activator="parent" location="top" :min-width="250" width="250">
                <v-list>
                  <v-list-item
                    v-for="(item, index) in bottomMenuItems"
                    :key="index"
                    :value="index">
                      <v-list-item-title v-if="item.title !== 'Support'">
                        <RouterLink :to="item.link" class="tw-flex tw-items-center tw-gap-3 tw-py-2 tw-capitalize tw-text-regular-text" :class="{'!tw-text-[#F22F3B]': item.title === 'Log out'}">
                          <i v-if="item.title === 'Settings'" :class="`mdi mdi-cog tw-text-2xl tw-leading-none`"></i>
                          <i v-if="item.title === 'Support'" :class="`mdi mdi-lifebuoy tw-text-2xl tw-leading-none`"></i>
                          <i v-if="item.title === 'Documents'" :class="`mdi mdi-file-document tw-text-2xl tw-leading-none`"></i>
                          <i v-if="item.title === 'My pay'" :class="`mdi mdi-cash tw-text-2xl tw-leading-none`"></i>
                          <i v-if="item.title === 'FAQ'" :class="`mdi mdi-help-circle tw-text-2xl tw-leading-none`"></i>
                          {{ item.title }}
                        </RouterLink>
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        <v-dialog width="500">
                          <template v-slot:activator="{ props }">
                            <a v-bind="props" class="tw-flex tw-items-center tw-gap-3 tw-py-2 tw-capitalize tw-text-regular-text">
                                <i :class="`mdi mdi-lifebuoy tw-text-2xl tw-leading-none`"></i>
                                {{ item.title }}
                            </a>
                          </template>
                          <template v-slot:default="{ isActive }">
                            <v-card>
                              <v-card-title>
                                <div class="tw-flex tw-justify-between tw-items-center">
                                  <h3 class="tw-flex tw-items-center tw-gap-x-2">
                                    <i :class="`mdi mdi-lifebuoy tw-text-2xl tw-leading-none`"></i>
                                    <span class="-tw-mt-[1px]">Support Ticket</span>
                                  </h3>
                                  <VBtn @click="isActive.value = false" color="#DADADA" flat rounded="circle" variant="outlined" width="30px" height="30px" min-width="0" class="tw-p-0 close-support">
                                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M13.0672 12.1828C13.1253 12.2409 13.1713 12.3098 13.2028 12.3857C13.2342 12.4615 13.2504 12.5429 13.2504 12.625C13.2504 12.7071 13.2342 12.7884 13.2028 12.8643C13.1713 12.9402 13.1253 13.0091 13.0672 13.0672C13.0091 13.1252 12.9402 13.1713 12.8643 13.2027C12.7885 13.2342 12.7071 13.2503 12.625 13.2503C12.5429 13.2503 12.4616 13.2342 12.3857 13.2027C12.3098 13.1713 12.2409 13.1252 12.1828 13.0672L7.00001 7.88358L1.8172 13.0672C1.69992 13.1844 1.54086 13.2503 1.37501 13.2503C1.20916 13.2503 1.0501 13.1844 0.932824 13.0672C0.815549 12.9499 0.749664 12.7908 0.749664 12.625C0.749664 12.4591 0.815549 12.3001 0.932824 12.1828L6.11642 6.99998L0.932824 1.81717C0.815549 1.69989 0.749664 1.54083 0.749664 1.37498C0.749664 1.20913 0.815549 1.05007 0.932824 0.932794C1.0501 0.815518 1.20916 0.749634 1.37501 0.749634C1.54086 0.749634 1.69992 0.815518 1.8172 0.932794L7.00001 6.11639L12.1828 0.932794C12.3001 0.815518 12.4592 0.749634 12.625 0.749634C12.7909 0.749634 12.9499 0.815518 13.0672 0.932794C13.1845 1.05007 13.2504 1.20913 13.2504 1.37498C13.2504 1.54083 13.1845 1.69989 13.0672 1.81717L7.88361 6.99998L13.0672 12.1828Z" />
                                    </svg>
                                  </VBtn>
                                </div>
                              </v-card-title>
                              <v-card-text>
                                <SupportForm />
                              </v-card-text>
                            </v-card>
                          </template>
                        </v-dialog>
                      </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                      <v-list-item-title>
                          <div class="tw-text-[#F22F3B] tw-leading-none tw-flex tw-items-center tw-cursor-pointer tw-py-2" @click="logOut">
                            <i class="mdi mdi-exit-to-app tw-text-2xl tw-inline-block tw-align-middle tw-flex-grow-0 tw-mr-3 tw-leading-[0]"></i>
                            <span>Log out</span>
                          </div>
                      </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template #append>
              <svg width="12" height="15" viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg" class="tw-ml-auto tw-grow-0">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0L12 6L0 6L6 0ZM6 15L0 9H12L6 15Z" />
              </svg>
            </template>
          </v-btn>
        </li>
      </ul>
    </div>

    <Transition name="slidec">
      <ActiveCampaigns class="tw-z-0" v-if="$route.path.includes('active-campaigns')"  />
    </Transition>
  </aside>
</template>

<style scoped lang="scss">
.slidec-enter-active,
.slidec-leave-active {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.slidec-enter-from,
.slidec-leave-to {
  transform: translate3d(-100%, 0, 0);
}
.nav-tile {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  z-index: $zIndex_nav;
}
.nav-divider {
  background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  height: 1px;
  border: none;
}
.nav-item {
  @apply tw-pt-0.5 tw-px-2 #{!important};
}
.nav-link {
  display: flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  transition:
  background-color,
  color 0.3s ease;
  @apply tw-px-4 tw-py-3 tw-rounded-lg;

  &:hover {
    @apply tw-bg-gray-100/10;
  }

  &.router-link-active {
    background-color: $color_secondary;
    color: white;

    .nav-icon {
      color: white;
    }
  }
}
.nav-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem(32px);
  height: rem(32px);
  font-size: 18px;
  transition: color 0.3s ease;
  @apply tw-mr-1 tw-text-purple-400;
}
.slide-right-enter-active,
.slide-right-leave-active {
  @apply tw-transition-all tw-duration-300 tw-ease-in-out;
}
.slide-right-enter-from,
.slide-right-leave-to {
  @apply -tw-translate-x-full;
}
::v-deep(.v-overlay__content) {
  @apply tw-left-0 #{!important};
  .v-list {
    @apply tw-rounded-b-none #{!important};
  }
}
::v-deep(.v-btn) {
  // grid-template-areas: "content";
  grid-template-columns: 32px 1fr 12px;
  &[aria-expanded="true"] {
    @apply tw-bg-white #{!important};
    .v-btn__prepend {
      > div {
        @apply tw-border-shyftoff-purple/40;
      }
      svg {
        @apply tw-fill-shyftoff-purple #{!important};
      }
    }

    .v-btn__content {
      span {
        @apply tw-text-regular-text #{!important};
      }
    }
    .v-btn__prepend,
    .v-btn__append {
      svg {
        @apply tw-fill-shyftoff-purple;
      }
    }
  }
  .v-btn__prepend,
  .v-btn__append {
    @apply tw-m-0 #{!important};
    svg {
      @apply tw-fill-white/50;
    }
  }
  .v-btn__content {
    @apply tw-text-left tw-overflow-hidden tw-text-ellipsis;

  }
}
.v-card {
  .v-card-text,
  .v-card-title {
    @apply tw-px-0 tw-pb-3 #{!important};
  }
  .v-card-title {
    @apply tw-pt-0 tw-text-shyftoff-purple;
  }
}
.v-btn.close-support {
    @apply tw-grid-cols-[auto_1fr_auto] #{!important};
    ::v-deep(.v-btn__content) {
      @apply tw-w-full;
      svg {
        @apply tw-fill-[#AEAEAE];
      }
    }
    &:hover {
      @apply tw-border-shyftoff-purple/20 tw-bg-[#f3f2fb]/50;
      ::v-deep(.v-btn__content) {
        span {
          @apply tw-text-shyftoff-purple;
        }
        svg {
          @apply tw-fill-shyftoff-purple/70;
        }
      }
    }
  }
</style>
